<template>
<div id="joinUs">
  <div class="banner">
  </div>
  <div class="nav">
    <ul>
      <li @click="toggle(1)" :class="{active:sub==1}">报名流程</li>
      <li @click="toggle(2)" :class="{active:sub==2}">招聘公告</li>
      <li @click="toggle(3)" :class="{active:sub==3}">报名通道</li>
      <li @click="toggle(4)" :class="{active:sub==4}">报名记录</li>
    </ul>
  </div>
  <div class="flow" v-show="sub==1">
    <div class="flowCt">
      <div class="flow-item">
          <img src="@/assets/imgs/application/user.png" /> 
          <span>注册账号</span>
          <label>根据个人手机号码成功注册账户</label>
      </div>
      <img class="arrow" src="@/assets/imgs/application/rightIcon.png" />
      <div class="flow-item">
          <img src="@/assets/imgs/application/position.png" /> 
          <span>选择岗位</span>
          <label>查看岗位详情</label>
          <label>选择意向岗位</label>
      </div>
      <img class="arrow" src="@/assets/imgs/application/rightIcon.png" />
      <div class="flow-item">
          <img src="@/assets/imgs/application/message.png" /> 
          <span>立即投递</span>
          <label>选中意向岗位点击岗位对应</label>
          <label><em>&lt;立即投递&gt;</em>按钮</label>
      </div>
      <p class="arrowBottom">
        <img src="@/assets/imgs/application/bottomIcon.png" />
      </p>
      <div class="flow-item">
          <img src="@/assets/imgs/application/table.png" /> 
          <span>提交报名表</span>
          <label>等待资格审核</label>
      </div>
      <img class="arrow" src="@/assets/imgs/application/leftIcon.png" />
      <div class="flow-item">
          <img src="@/assets/imgs/application/upload.png" /> 
          <span>上传照片</span>
          <label>点击<em>&lt;上传&gt;</em>按钮上传指定要求照片</label>
      </div>
      <img class="arrow" src="@/assets/imgs/application/leftIcon.png" />
      <div class="flow-item">
          <img src="@/assets/imgs/application/edit.png" /> 
          <span>填写报名表</span>
          <label>按照报名表要求填写报名</label>
          <label>表中信息</label>
      </div>
    </div>
    <div class="flow-tips">
      报名期间注意请随时电脑登录报名系统，首页右上角&lt;查询报名状态&gt;，查询材料审核状态
    </div>
  </div>
  <div class="notice" v-show="sub==2">
     <h3>{{noticeInfo.title}}</h3>
    <div class="notice-ct" v-html="noticeInfo.consultationInfo"></div>
    <br/><br/>
    <span><h4>公告信息：</h4></span>
    <br/>
    <div class="notice-ct" v-html="noticeInfo.contents"></div>
  </div>
  <div class="thorough" v-show="sub==3">
    <div class="selectTh"  v-show="!accessSub">
       <p>
         <img src="@/assets/imgs/application/qrcode.png" />
       </p>
    </div>
    <div class="comapnyPost" v-show="accessSub">
        <div class="employSearch">
          <el-input v-model="recruitmentPositions" placeholder="请输入岗位进行搜索"></el-input>
          <el-button type="primary" @click="getTalentIntroductionPositions">搜索</el-button>
        </div>
        <div class="employMenu">
          <el-menu active-text-color="#0077FF">
            <el-menu-item v-for="(item,index) in enterpriseList" :key="index"  :index="index+' '"  @click="selectUnit(item)">
              <template slot="title">
                <span>{{item.nameOfEmployer}}</span>
              </template>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="employerList">
            <el-table
              :data="employerList"
              >
              <el-table-column type="expand">
                <template slot-scope="props">
                    <p class="expandEmploy">
                      <span>年龄要求：</span>
                      <label>&nbsp;&nbsp;{{ props.row.ageRequirements }}</label>
                    </p>
                    <p class="expandEmploy">
                      <span>学历要求：</span>
                      <label>&nbsp;&nbsp;{{ props.row.educationalRequirements }}</label>
                    </p>
                    <p class="expandEmploy">
                      <span>学位要求：</span>
                      <label>&nbsp;&nbsp;{{ props.row.academicDegree }}</label>
                    </p>
                    <p class="expandEmploy">
                      <span>专业名称：</span>
                      <label>&nbsp;&nbsp;{{ props.row.professionalName }}</label>
                    </p>
                    <p class="expandEmploy">
                      <span>研究生专业三级目录限制要求：</span>
                      <label>&nbsp;&nbsp;{{ props.row.professionalRequirements }}</label>
                    </p>
                    <p class="expandEmploy">
                      <span>岗位描述：</span>
                      <label>&nbsp;&nbsp;{{ props.row.jobDescription }}</label>
                    </p>
                    <p class="expandEmploy">
                      <span>备注：</span>
                      <label>&nbsp;&nbsp;{{ props.row.remark }}</label>
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  label="岗位代码"
                  align="center"
                  prop="nameOfSuperiorUnit">
               </el-table-column>
                <el-table-column
                  label="单位名称"
                  align="center"
                  prop="nameOfEmployer">
               </el-table-column>
                <el-table-column
                  label="专业名称"
                  align="center"
                  prop="professionalName">
               </el-table-column>
               <el-table-column
                  label="岗位类别"
                  align="center"
                  prop="jobCategory">
               </el-table-column>
               <el-table-column
                  label="招聘人数"
                  width="120"
                  align="center"
                  prop="recruitingNum">
               </el-table-column>
               <el-table-column
                  fixed="right"
                  align="center"
                  label="操作"
                  width="160">
                  <template slot-scope="scope">
                    <el-button @click="deliver(scope.row)" type="text" size="small">立即投递</el-button>
                  </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
    <div class="deliverDialog">
      <el-dialog
          title="立即报名"
          :visible.sync="showDeliver"
          width="620px"
         >
          <el-form ref="form" :model="deliverForm" :rules="ruleForm" label-width="100px">
            <el-form-item label="姓名" prop="userName">
              <el-input v-model="deliverForm.userName"></el-input>
            </el-form-item>
            <el-form-item label="近期彩色免冠一寸照片" prop="headSculpture">
              <el-upload class="avatar-uploader" :on-success="avatarSuccess" :action="baseUrl" :show-file-list="false">
                <img v-if="deliverForm.headSculpture" :src="deliverForm.headSculpture" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="身份证号" prop="idCard">
              <el-input v-model="deliverForm.idCard"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-input v-model="deliverForm.sex"></el-input>
            </el-form-item>
            <el-form-item label="民族" prop="nation">
              <el-input v-model="deliverForm.nation"></el-input>
            </el-form-item>
            <el-form-item label="出生日期" >
              <el-input v-model="deliverForm.dateOfBirth"></el-input>
            </el-form-item>
            <el-form-item label="籍贯请选择" prop="phone">
              <el-input v-model="deliverForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="生源地请选择" prop="phone">
              <el-input v-model="deliverForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="政治面貌请选择" prop="phone">
              <el-input v-model="deliverForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="最高学历" prop="education">
              <el-select clearable v-model="deliverForm.education" placeholder="请选择最高学历">
                <el-option v-for="item in backgroundTypeList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <span>
              学位填写说明：单个学位人员只需填写一个即可；多个学位的报名人员，在相应学历下点击“添加明细”即可。
            </span>
            <el-form-item label="毕业学校" prop="phone">
              <el-input v-model="deliverForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="专业" prop="phone">
              <el-input v-model="deliverForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="获得学位时间" prop="phone">
              <el-input v-model="deliverForm.phone"></el-input>
            </el-form-item>

            <el-form-item label="手机号" prop="phone">
              <el-input v-model="deliverForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="deliverForm.mailbox"></el-input>
            </el-form-item>


          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="showDeliver = false">取 消</el-button>
            <el-button type="primary" @click="levelRegistration">确 定</el-button>
          </span>
        </el-dialog>
    </div>
  </div>
  <div class="record" v-show="sub==4">
     <el-table
      :data="recordList"
      style="width: 100%">
        <el-table-column
          prop="nameOfEmployer"
          label="报名单位名称"
          align="center"
         >
        </el-table-column>
        <el-table-column
          prop="recruitmentPositions"
          label="报名岗位"
          align="center"
         >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="mailbox"
          label="邮箱"
          align="center"
         >
        </el-table-column>
        <el-table-column
          label="一寸照片"
          align="center"
         >
         <template slot-scope="scope">
          <img class="headSculpture" :src="scope.row.headSculpture" />
         </template>
        </el-table-column>
        <el-table-column
          label="报名状态"
          align="center"
          width="120"
         >
         <template slot-scope="scope">
           <el-button type="text" size="small" v-show="scope.row.status==0">报名失败</el-button>
           <el-button type="text" size="small" v-show="scope.row.status==1">审核中</el-button>
           <el-button type="text" size="small" v-show="scope.row.status==2">审核通过</el-button>
         </template>
        </el-table-column>
     </el-table>
  </div>
</div>
</template>

<script>
import {getRegistrationList, getRecruitmentAnnouncement,levelRegistration,getDictData,getTalentIntroductionEnterprises,getTalentIntroductionPositions } from "@/api/index.js";
export default {
  name: "joinUs",
  data() {
    return {
      info: {},
      sub:1,
      noticeInfo:{},
      accessSub:'',
      enterpriseList:[],
      employerList: [],
      talentIntroductionInfoId:'',
      recruitmentPositions:'',
      showDeliver:false,
      deliverForm:{
        education:'',
        idCard:'',
        mailbox:'',
        phone:'',
        talentIntroductionInfoId:'',
        userName:'',
        headSculpture:''
      },
      backgroundTypeList:[],
      baseUrl:'',
      userInfo:{},
      recordList:[],
      ruleForm:{
         userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
         ],
         phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
         ],
         idCard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' }
         ],
         education: [
          { required: true, message: '请选择最高学历', trigger: 'change' }
         ],
         headSculpture: [
          { required: true }
         ],
      }
    }
  },
  mounted() {
    this.getRecruitmentAnnouncement()
    this.getTalentIntroductionEnterprises()
    this.baseUrl = process.env.VUE_APP_BASE_API + '/webApi/common/upload'
    this.userInfo = JSON.parse(localStorage.getItem('userInfo')) 
    this.getRegistrationList()
  },
  methods: {
    getRegistrationList(){
      getRegistrationList().then(res=>{
        this.recordList = res.rows
        console.log( this.recordList )
      })
    },
    avatarSuccess(res){
       this.deliverForm.headSculpture = res.url
    },
    levelRegistration(){
      levelRegistration(this.deliverForm).then(res=>{
        if(res.code==200){
          this.$message({
            message: '报名成功',
            type: 'success'
          })
          this.showDeliver = false
        }else{
          this.$message({
            message:res.msg,
            type: 'warning'
          })
        }
      })
    },
    deliver(row){
      this.showDeliver = true
       this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })

      this.deliverForm.talentIntroductionInfoId = row.id
      this.deliverForm.userName = this.userInfo.userName
      this.deliverForm.phone = this.userInfo.phone
      this.deliverForm.mailbox = this.userInfo.mailbox
      this.getDictData('background_type_two')
    },
    getDictData(dictType) {
      getDictData({
        dictType: dictType
      }).then(res => {
        if (dictType == 'background_type_two') {
          this.backgroundTypeList = res.rows
        }
      })
    },  
    selectUnit(res){
      this.talentIntroductionInfoId = res.talentIntroductionId
      this.nameOfEmployer = res.nameOfEmployer
      this.getTalentIntroductionPositions()
    },
    toggle(index) {
      this.sub = index
      this.accessSub = ''
    },
    getRecruitmentAnnouncement(){
      getRecruitmentAnnouncement({
        id: this.$route.query.id
      }).then(res=>{
        this.noticeInfo = res.data
      })
    },
    getTalentIntroductionEnterprises(){
      getTalentIntroductionEnterprises({
        /*type: this.accessSub*/
        id: this.$route.query.id,
        type: 0
      }).then(res=>{
        this.enterpriseList = res.rows
      })
    },
    selectAccess(index){
      this.accessSub = index
      this.getTalentIntroductionEnterprises()
    },
    getTalentIntroductionPositions(){
      getTalentIntroductionPositions({
        talentIntroductionInfoId:this.talentIntroductionInfoId,
        nameOfEmployer: this.nameOfEmployer,
        recruitmentPositions:this.recruitmentPositions
      }).then(res=>{
        this.employerList = res.rows
      })
    }
  }
};
</script>

<style lang="scss">
#joinUs {
  width: 100%;
  min-height: 600px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  .banner {
  height: 460px;
  width: 100%;
  background: url('../../assets/imgs/company/joinUs.png');
  background-size: cover;
}

  .nav {
    background: linear-gradient(to right, #407cd4 5%, #339DFF, #407cd4);
    height: 80px;
    line-height: 80px;

    ul {
      width: 1200px;
      margin: 0 auto;

      li {
        float: left;
        font-size: 23px;
        width: 240px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }

      li:hover {
        background: #0071fb;
        transition: all .2s linear;
      }
    }
    .active{
      background: #0071fb;
    }
  }
  .flowCt{
    width: 1200px;
    height: 523px;
    background: #F6FAFF;
    border-radius: 2px;
    margin:20px auto;
    .flow-item{
      width: 330px;
      height: 140px;
      padding-top: 47px;
      text-align: center;
      font-size: 18px;
      color: #222222;
      float: left;
      line-height: 26px;
      span{
        display: block;
        margin-bottom:5px;
      }
      label{
        color: #666;
        display: block;
        em{
          color: #0071fb;
          font-style: normal;
        }
        
      }
    }
    .arrow{
      float: left;
      width: 20px;
      height: 35px;
      margin:100px 30px;
    }
    
    .arrowBottom{
    clear: both;
    text-align: right;
    margin-right: 180px;
    img{
        width: 35px;
        height: 20px;     
    }
    }
  }
  .flow-tips{
    width: 1200px;
    height: 100px;
    background: #D0E6FF;
    border-radius: 4px;
    margin: 50px auto;
    line-height: 100px;
    text-align: center;
    font-size: 22px;
    letter-spacing: 1px;
    color: #0071fb;
  }
  .notice{
    width: 1120px;
    min-height:300px;
    background: #F6FAFF;
    border-radius: 2px;
    margin:20px auto;
    padding:40px;
    overflow: hidden;
    h3{
      text-align: center;
      margin-bottom: 30px;
      font-size: 29px;
      color: #333;
    }
    p{
      font-size: 18px;
      color: #333;
      line-height: 40px;
      text-indent:2em;
      strong{
       padding:7px 10px;
       border-radius: 5px;
      }
    }
  }
  .thorough{
    .selectTh{
      width: 1200px;
      margin:50px auto;
      overflow: hidden;
      p{
        width: 420px;
        float: left;
        height: 450px;
        background: #fff;
        margin-left: 33%;
        border-radius: 12px;
        text-align: center;
        cursor: pointer;
        img{
          width: 410px;
          margin-top: 30px;
        }
        span{
          display: block;
          font-size: 30px;
          color: #666;
          margin-top: 10px;
        }
        label{
          width: 201px;
          display:inline-block;
          text-align: center;
          margin-top:25px;
          color: #fff;
          line-height: 46px;
          font-size: 18px;
          height: 46px;
          background: #0077FF;
          border-radius: 4px;
          cursor: pointer;
        }
        label:hover{
           background:#2b77e8
        }
      }
    }
    .comapnyPost{
      width: 1200px;
      margin: 20px auto;
      overflow: hidden;
    }
    .employMenu{
      float: left;
      margin-right:15px;
    }
    .employerList{
      width: 880px;
      float: left;
      .expandEmploy{
        clear: both;
        margin-bottom: 15px;
        overflow: hidden;
        span{
          font-weight: 600;
          display: block;
          float: left;
          width: 110px;
          text-align: right;
          line-height: 30px;
        }
        label{
          display: block;
          float: left;
          white-space: pre-wrap;
          line-height: 30px;
          letter-spacing: 1px;
          width: 700px;
        }
      }
    }
  }
  .record{
    width: 1200px;
    margin: 20px auto;
    .headSculpture{
      width: 60px;
    }
  }
}
</style>

<style lang="scss">
.thorough{
  .el-menu{
    width: 300px;
  }
  .el-menu-item-group__title{
    padding: 0;
  }
  .employSearch{
    margin-bottom: 20px;
    margin-left: 200px;
     .el-input{
      width: 600px;
     }
    .el-input__inner{
      width: 600px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      font-size: 14px;
      border: 1px solid #0071fb;
      border-radius: 5px;
      color: #555;
    }
    .el-button--primary{
      background-color: #0071fb;
      color: #FFF;
      height: 45px;
      width: 120px;
      text-align: center;
      font-size: 15px;
      letter-spacing: 2px;
      border: none;
      border-radius: 5px;
      box-shadow: #DDD 0px 0px 5px;
      cursor: pointer;
      margin-left:25px;
      line-height: 24px;
    }
  }
  .deliverDialog{
    .el-dialog__body{
      padding: 20px;
    }
    .el-input__inner{
      width: 400px;
    }
     .avatar-uploader {
        width: 80px;
        height: 80px;
        text-align: center;
        border: 1px solid #ddd;
        display: inline-block;
      }

      .avatar {
        width: 80px;
        height: 80px;
      }

      .avatar-uploader-icon {
        font-size: 20px;
        color: #ddd;
        margin-top: 30px;
      }
  }
}
</style>
